import { Button, Container, Grid } from '@mui/material'
import React from 'react'
import './styles.css'
import { Link } from 'react-router-dom'

function CryptoCurrency({ coins }) {

    return (
        <>
            <div className='cryptoWrapper'>
                <Container fluid >
                    <Grid container fluid >
                        {
                            coins.slice(0, 4).map((item) => (

                                <Grid lg={3} md={6} sm={6} xs={12} className="cryptoDetailWrapper" key={item?.node?.id}>
                                    <div className='oneByOne'>
                                        <div className='cryptoHeaderContainer'>
                                            <div className='cryptoLogo' style={{ backgroundImage: `url(${item.image})` }}></div>
                                            <div style={{display:"flex" , alignItems:'center' , justifyContent:"space-evenly", width:"100%" , marginTop:"16px"}}>
                                                <div>
                                                    <p className='cryptoName'>{item.name}/<span>{item.symbol}</span></p>
                                                </div>
                                                <div>
                                                    <p className={item.price_change_percentage_24h < 0 ? 'negChange' : 'posChange'}>{item.price_change_percentage_24h.toFixed(2)}%</p>
                                                </div>
                                            </div>
                                            <div style={{marginTop:"16px"}}>
                                                <p className='cryptoCurrency'>Price : ${item.current_price}</p>
                                            </div>
                                            <div>
                                                <p className='volumeValue'>Volume : {item.total_volume}$</p>
                                            </div>
                                            <Link to='/markets' style={{width:'100%'}}>
                                                <Button className="cryptocurrencyButton">
                                                        View More
                                                </Button>
                                            </Link>
                                        </div>
                                    </div>
                                </Grid>

                            ))
                        }

                    </Grid>
                </Container>
            </div>
        </>
    )
}

export default CryptoCurrency