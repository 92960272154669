import { Button, Container, Grid } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import './styles.css'
import Cookies from 'universal-cookie';
import Blogs from '../../sections/blog';


function Body({ coins }) {
    const cookie = new Cookies()

    return (
        <>
            <div className="bodyWrapper">
                <div style={{background:"rgba(0,0,0,0.4)" , height:"100%"}}>
                    <Container style={{ padding: 0 }}>
                        <Grid container fluid >
                            <Grid item lg={8} md={8} sm={6} xs={12} style={{ padding: 0 }}>
                                <div className='bodyContainer'>
                                    <p className='bodyTitle'>The future of finance with groundbreaking innovations.</p>
                                    <p className='bodyDescription'>Explore a new universe of digital finance with limitless opportunities in cryptocurrency.</p>
                                    {
                                        cookie.get('token') ?
                                            null
                                            :
                                            <div className='buttonsContainer'>
                                                <Link to="/signup" style={{ textDecoration: 'none', color: 'white' }}>
                                                    <Button
                                                        variant="contained"
                                                        className='createAccount'
                                                    >
                                                        Create Account
                                                    </Button>
                                                </Link>

                                                {/* <Link to="/markets" style={{ textDecoration: 'none', color: 'white' }}>
                                                    <Button
                                                        variant="text"
                                                        className='bottom'
                                                    >
                                                        View Market
                                                    </Button>
                                                </Link>  */}
                                            </div>
                                    }
                                </div>
                            </Grid>

                            <Grid lg={4} md={4} sm={6} xs={12}>
                            <Blogs />
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </div>

        </>
    )
}

export default Body