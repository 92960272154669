import { Container, Grid } from '@mui/material'
import React from 'react'
import './styles.css'
import SocialMedia from './socialMedia'

function Footer() {

    return (
        <div className='footerWrapper'>
            <Container>
                <Grid container fluid >
                    <Grid lg={12} md={12} sm={12} xs={12} >
                        <div className='footerSectionsContainer'>
                            <div className='footerSectionOneContainer'>
                                <div className='footerSectionOneLinks'>
                                    <div>
                                        <p>Terms & conditions</p>
                                    </div>
                                    <div style={{ marginLeft: 16 }}>
                                        <p>Privacy Policy</p>
                                    </div>
                                </div>
                                <div className='footerLogo'>
                                </div>
                                <div className='footerSectionOneLinks'>
                                    <div>
                                        <p>Cookies</p>
                                    </div>
                                    <div style={{ marginLeft: 16 }}>
                                        <p>Refunds</p>
                                    </div>
                                    {/* <div style={{ marginLeft: 16 }}>
                                        <p>Liscence</p>
                                    </div> */}
                                </div>
                            </div>
                            <hr style={{ backgroundColor: 'white', opacity: '0.32' }}></hr>
                            <div className='footerSectionTwoContainer'>
                                <p>© Copy Right-2022 All rights reserved.</p>
                            </div>
                        </div>
                    </Grid>

                </Grid>
            </Container>
        </div>
    )
}

export default Footer
