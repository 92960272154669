import { Box, Container, Grid } from '@mui/material'
import React from 'react'
import './styles.css'
import coin1 from "../../assets/coins/coin1.png"
import coin2 from "../../assets/coins/coin2.png"
import coin3 from "../../assets/coins/coin3.png"
import coin4 from "../../assets/coins/coin4.png"
import coin5 from "../../assets/coins/coin5.png"
import coin6 from "../../assets/coins/coin6.png"
import coin8 from "../../assets/coins/coin8.png"
import coin9 from "../../assets/coins/coin9.png"
import mainLogo from "../../assets/images/logo.png"


function CoinsImages() {
    return (
        <div className='CoinsImagesWrapper'>
            <Container fluid>
                <Grid container>
                    <Grid item lg={6} xs={12}>
                        <Box className='CoinsTextContainer'>
                            <h1>Trade and exchange effortlessly.</h1>
                            <h1>Connect all your wallets in one place.</h1>
                            <h1>View and manage your digital assets.</h1>
                            <h1 style={{color:"var(--cardSubTitle)" , textDecoration:"underline"}}>Welcome to the future of cryptocurrency.</h1>
                        </Box>
                    </Grid>
                    <Grid item lg={6} xs={12} container fluid>
                        <div className='CoinsImagesContainer'>
                            <section class="main-container">
                                <div class="main">
                                    <div class="big-circle">
                                        <div class="icon-block">
                                            <img src={coin1} alt="web design icon" />
                                        </div>
                                        <div class="icon-block">
                                            <img src={coin2} alt="game design icon" />
                                        </div>
                                        <div class="icon-block">
                                            <img src={coin3} alt="game dev icon" />
                                        </div>
                                        <div class="icon-block">
                                            <img src={coin4} alt="ui-ux icon" />
                                        </div>
                                    </div>
                                    <div class="circle">
                                        <div class="icon-block">
                                            <img src={coin5} alt="app icon" />
                                        </div>
                                        <div class="icon-block">
                                            <img src={coin6} alt="blockchain icon" />
                                        </div>
                                        <div class="icon-block">
                                            <img src={coin9} alt="ar-vr icon" />
                                        </div>
                                        <div class="icon-block">
                                            <img src={coin8} alt="artificial intelligence icon" />
                                        </div>
                                    </div>
                                    <div class="center-logo">
                                        <img src={mainLogo} alt="logo" />
                                    </div>
                                </div>
                            </section>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default CoinsImages