import { Container, Grid } from '@mui/material'
import React from 'react'
import './style.css'
import Lottie from 'react-lottie';
import * as animationData from '../../assets/lottie/crypto-coins.json'


function MobileApp() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <>
            <div className='cryptocurrencyWrapper'>
                <Container fluid >
                        <div style={{display:"flex" , alignItems:"center" , justifyContent:"flex-end"}}>
                            <div className='cryptocurrencyInfoWrapper'>
                                <div className='cryptocurrencyInfoContainer'>
                                    <h2 className='cryptocurrencyInfoTitle'>Cryptocurrency Overview</h2>
                                    <p className='cryptocurrencyInfoText'>
                                        Cryptocurrencies are digital assets designed to work as a medium of exchange, using cryptography to secure transactions.
                                        They operate on decentralized networks based on blockchain technology, which ensures transparency and security.
                                        The introduction of Bitcoin in 2009 marked the beginning of a new era in digital finance.
                                        <br></br>
                                        <br></br>
                                        Since then, the crypto market has expanded with thousands of different cryptocurrencies,
                                        each offering unique benefits and functionalities. These digital currencies offer an alternative to traditional financial systems,
                                        allowing for greater privacy and the ability to bypass intermediaries like banks and payment processors.
                                        <br></br>
                                        <br></br>
                                        Despite the benefits, cryptocurrencies face challenges such as regulatory scrutiny, security vulnerabilities,
                                        and market volatility. Nonetheless, they are increasingly being integrated into mainstream financial systems,
                                        promising continued growth and innovation in the future.
                                        <br></br>
                                        <br></br>
                                    </p>
                                    <hr style={{margin:"8px 16px"}}></hr>
                                    <h2 className='cryptocurrencyInfoTitle'>Key Players</h2>
                                    <p className='cryptocurrencyInfoText'>
                                        Bitcoin, the first and most well-known cryptocurrency, remains a dominant force in the market.
                                        However, other cryptocurrencies like Ethereum, known for its smart contract functionality, and Ripple,
                                        designed for fast international transfers, have also gained traction.
                                        <br></br>
                                        <br></br>
                                        Each cryptocurrency offers distinct features, catering to different use cases and user needs.
                                        The rise of DeFi (Decentralized Finance) and NFTs (Non-Fungible Tokens) has further propelled the adoption of cryptocurrencies,
                                        showcasing their versatility beyond mere currency.
                                        <br></br>
                                        <br></br>
                                        While the potential for high returns attracts many investors, it is essential to understand the risks involved,
                                        including price fluctuations and the security of digital wallets. As the crypto landscape evolves,
                                        staying informed about market trends and technological advancements is crucial.
                                        <br></br>
                                        <br></br>
                                    </p>
                                    <hr style={{margin:"8px 16px"}}></hr>
                                    <h2 className='cryptocurrencyInfoTitle'>Advantages</h2>
                                    <p className='cryptocurrencyInfoText'>
                                        One of the main advantages of cryptocurrencies is their accessibility, allowing anyone with internet access to participate in the digital economy.
                                        Transactions can be completed quickly and at a low cost, making them ideal for international transfers and microtransactions.
                                        <br></br>
                                        <br></br>
                                        Cryptocurrencies also provide users with greater control over their finances, reducing reliance on traditional banking systems.
                                        This decentralization ensures that users retain ownership of their assets without the risk of censorship or restrictions.
                                        <br></br>
                                        <br></br>
                                        As blockchain technology continues to develop, cryptocurrencies are poised to revolutionize various industries,
                                        from finance and healthcare to supply chain management and beyond.
                                        <br></br>
                                        <br></br>
                                    </p>
                                </div>
                            </div>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default MobileApp