import { Container, Grid } from '@mui/material'
import React from 'react'
import './styles.css'
import { RiSecurePaymentLine } from 'react-icons/ri'
import { IoNavigateSharp } from 'react-icons/io5'
import { IoNewspaperSharp } from 'react-icons/io5'

function Features() {

    return (
        <>
            <div className='featuresWrapper'>
                <Container fluid>
                    <Grid container fluid >
                        <Grid lg={12} md={12} sm={12} xs={12} >
                            <div className='featuresTitleContainer'>
                                <div className='featuresTitle'>
                                    <p>Our Services</p>
                                </div>
                                <div className='featuresSubTitle'>
                                    <p>Explore our offerings as the <br></br>leading platform for cryptocurrency trading</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid lg={12} md={12} sm={12} xs={12}>
                            <div className='featuresContainer'>
                                <div className='featuresCardsContainer'>
                                    <div className='featuresCardIconContainer'>
                                        <RiSecurePaymentLine color='#FDA736' size={70} />
                                    </div>
                                    <div className='featuresCardTitleContainer'>
                                        <p>Secure<br></br>Digital Wallet</p>
                                    </div>
                                    <div className='featuresCardTextContainer'>
                                        <p>A robust wallet for storing, sending, and receiving<br></br> cryptocurrencies safely and easily.</p>
                                    </div>
                                </div>
                                <div className='featuresCardsContainer'>
                                    <div className='featuresCardIconContainer'>
                                        <IoNavigateSharp color='#FDA736' size={65} />
                                    </div>
                                    <div className='featuresCardTitleContainer'>
                                        <p>Crypto<br></br>Trading Platform</p>
                                    </div>
                                    <div className='featuresCardTextContainer'>
                                        <p>A comprehensive platform to buy, sell, and trade<br></br> digital assets with global users.</p>
                                    </div>
                                </div>
                                <div className='featuresCardsContainer'>
                                    <div className='featuresCardIconContainer'>
                                        <IoNewspaperSharp color='#FDA736' size={65} />
                                    </div>
                                    <div className='featuresCardTitleContainer'>
                                        <p>Crypto<br></br>Payment Solution</p>
                                    </div>
                                    <div className='featuresCardTextContainer'>
                                        <p>A streamlined service enabling businesses to accept<br></br> cryptocurrency payments efficiently.</p>
                                    </div>
                                </div>
                            </div>

                        </Grid>
                    </Grid>
                </Container>
            </div>

        </>
    )
}

export default Features