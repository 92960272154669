import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Navbar from '../../components/navbar'
import Footer from '../../sections/footer'

function PublicLayout({ user }) {
    const location = useLocation()
    console.log(location)
    return (
        <div>
            <Navbar user={user} />
            <Outlet />
            {
                location?.pathname !== '/markets' &&
                <Footer />
            }
        </div>
    )
}

export default PublicLayout